import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import useDeviceSize from "../hooks/use-device-size";
import axios from "axios";

import bannerImg from "../images/personal-stories/hero-personal-stories.webp";
import bannerImgMobile from "../images/personal-stories/hero-personal-stories-mobile.webp";
import heroName from "../images/personal-stories/hero-personal-stories-name.webp";
import { BsChevronRight } from "react-icons/bs";
import kristen from "../images/icons/thumb-round-kristen.webp";
import kristenName from "../images/icons/thumb-name-kristen.svg";
import celina from "../images/share-your-story/celina thumbnail.webp";
import celinaName from "../images/share-your-story/celina_name.svg";
import chrisName from "../images/share-your-story/chris_name.svg";
import chris from "../images/share-your-story/chris_thumbnail.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../sass/_orphan.scss";
import "./share-your-story.scss";
import ThankYouModal from "../components/modals/thank-you-modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";

const ShareYourStoryPage = () => {
    const deviceSizes = useDeviceSize();
    const [modalOpen, setIsOpen] = useState(false);
    const [isShareSubmit, setIsShareSubmit] = useState(false);

    function closeModal() {
        setIsOpen(false);
        reset();
    }

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
    });

    const handleSubmitForm = async (data) => {
        setIsShareSubmit(true);
        const { fname, lname, email, phoneNumber, agreeTerms } = data;
        const payload = {
            firstname: fname,
            lastname: lname,
            email: email,
            phone: phoneNumber,
            ack: agreeTerms,
        };

        try {
            const response = await axios.post("/crm/share", payload, {});
            // console.log("response");
            // console.log(response);
            setIsOpen(true);
            // Request is successful
            setIsShareSubmit(false);
            return response;
        } catch (error) {
            console.error(error);
            setIsShareSubmit(false);
        }
    };

    const settings = {
        arrows: false,
        dots: true,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    function validateNumber(e) {
        if (
            !(
                (e.keyCode > 95 && e.keyCode < 106) ||
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode === 8
            )
        ) {
            e.preventDefault();
        }
    }

    return (
        <HcpLayout
            pageClass="share-your-story-page orphan-page"
            jobCode="US-WAK-2300486/Jan 2024"
            copyRightYear="2024"
            isGhostPage="true"
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                className="banner-mobile"
                                alt="Share Your Story"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                className="banner-desktop"
                                alt="Share Your Story"
                            />
                        )}
                        <div className="centered-content">
                            <div className="hero-name">
                                <img src={heroName} alt="Share Your Story" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="centered-content">
                <div className="page-heading ph-btm">
                    <h1 className="font-40">Share Your Story</h1>
                </div>
            </section>
            <section className="cta-block">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <div className="content-copy">
                                <h2>Your story matters to us</h2>
                                <p style={{ lineHeight: "22px" }}>
                                    Everyone has a unique story to tell. Sharing
                                    your experiences can help educate others
                                    living with narcolepsy and inspire them to
                                    share their own story. This is why we're
                                    looking for people like you who are
                                    interested in sharing their stories of their
                                    narcolepsy journey and their experience with
                                    WAKIX.
                                </p>
                                <p>
                                    If you are 18 years or older and are
                                    interested in learning more about this
                                    opportunity, please call 855-WAKIXSTORY
                                    (855-925-4978) or submit the form below. We
                                    look forward to hearing from you!
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="form-wrap gray-bg">
                    <div className="centered-content">
                        <Row>
                            <Col xs={12}>
                                <div className="form-holder">
                                    <form
                                        className="form"
                                        onSubmit={handleSubmit(
                                            handleSubmitForm
                                        )}
                                    >
                                        <span className="req">
                                            *Indicates a required field.
                                        </span>
                                        <Row>
                                            <Col lg={6}>
                                                <div
                                                    className={`form-group ${
                                                        errors.fname
                                                            ? "error-msg"
                                                            : ""
                                                    }`}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="First Name*"
                                                        className="form-control"
                                                        name="fname"
                                                        {...register("fname", {
                                                            required: true,
                                                        })}
                                                    />
                                                    <span className="required-msg">
                                                        Please enter a valid
                                                        first name
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div
                                                    className={`form-group ${
                                                        errors.lname
                                                            ? "error-msg"
                                                            : ""
                                                    }`}
                                                >
                                                    <input
                                                        type="text"
                                                        name="lname"
                                                        placeholder="Last Name*"
                                                        className="form-control"
                                                        {...register("lname", {
                                                            required: true,
                                                        })}
                                                    />
                                                    <span className="required-msg">
                                                        Please enter a valid
                                                        last name
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div
                                                    className={`form-group ${
                                                        errors.email
                                                            ? "error-msg"
                                                            : ""
                                                    }`}
                                                >
                                                    <input
                                                        placeholder="Email*"
                                                        className="form-control"
                                                        name="email"
                                                        {...register("email", {
                                                            required: true,
                                                            pattern: {
                                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                message:
                                                                    "Please enter a valid email",
                                                            },
                                                        })}
                                                    />
                                                    <span className="required-msg">
                                                        Please enter a correctly
                                                        formatted email
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div
                                                    className={`form-group ${
                                                        errors.phoneNumber
                                                            ? "error-msg"
                                                            : ""
                                                    }`}
                                                >
                                                    <input
                                                        type="text"
                                                        maxLength={10}
                                                        onKeyDown={
                                                            validateNumber
                                                        }
                                                        name="phoneNumber"
                                                        placeholder="Phone number* "
                                                        className="form-control"
                                                        {...register(
                                                            "phoneNumber",
                                                            {
                                                                required: true,
                                                                minLength: 6,
                                                                maxLength: 10,
                                                            }
                                                        )}
                                                    />
                                                    <span className="required-msg">
                                                        Please enter a correctly
                                                        formatted phone number
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div
                                            className={`form-group form-check ${
                                                errors.agreeTerms
                                                    ? "error-msg"
                                                    : ""
                                            }`}
                                        >
                                            <div className="wrap">
                                                <Controller
                                                    name="agreeTerms"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <input
                                                            type="checkbox"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                                <span className="text">
                                                    By checking this box, I
                                                    certify that I am at least
                                                    18 years old and Harmony
                                                    Biosciences and companies
                                                    acting on its behalf may
                                                    contact me.
                                                </span>
                                            </div>
                                            <span className="required-msg">
                                                Please check the box to continue
                                            </span>
                                        </div>
                                        <div className="btn-wrap">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                // disabled={isSubmitting || isShareSubmit}
                                                disabled={isShareSubmit}
                                            >
                                                submit <BsChevronRight />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="speaking-wrap bg-green">
                    <div className="centered-content">
                        <h2 className="speaking-tag">Others are speaking up</h2>
                        <div className="speaking-slider">
                            <Slider {...settings} className="speaking-slider">
                                <div className="speaking-slider__item">
                                    <div className="info">
                                        <div className="media">
                                            <img src={kristen} alt="author" />
                                            <div className="mobile">
                                                <div className="name">
                                                    <img
                                                        src={kristenName}
                                                        alt="Name"
                                                    />
                                                    <p>
                                                        Living with narcolepsy{" "}
                                                        <br />
                                                        Taking WAKIX since 2020
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-text">
                                            <span className="cta-headline">
                                                “Narcolepsy is definitely a
                                                journey and WAKIX has become a
                                                critical piece of this journey
                                                for me.”
                                            </span>
                                            <div className="desktop">
                                                <div className="name">
                                                    <img
                                                        src={kristenName}
                                                        alt="Name"
                                                    />
                                                    <p>
                                                        Living with narcolepsy{" "}
                                                        <br />
                                                        Taking WAKIX since 2020
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="speaking-slider__item">
                                    <div className="info">
                                        <div className="media">
                                            <img src={celina} alt="Celina" />
                                            <div className="mobile">
                                                <div className="name">
                                                    <img
                                                        src={celinaName}
                                                        alt="Celina Name"
                                                    />
                                                    <p>
                                                        Living with narcolepsy{" "}
                                                        <br />
                                                        Taking WAKIX since 2020
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-text">
                                            <span className="cta-headline">
                                                “Most people think I'm just
                                                dramatic or didn't get a good
                                                night’s sleep the night
                                                before—but it’s much different.”
                                            </span>
                                            <div className="desktop">
                                                <div className="name">
                                                    <img
                                                        src={celinaName}
                                                        alt="Celina Name"
                                                    />
                                                    <p>
                                                        Living with narcolepsy{" "}
                                                        <br />
                                                        Taking WAKIX since 2020
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="speaking-slider__item">
                                    <div className="info">
                                        <div className="media">
                                            <img src={chris} alt="Chris" />
                                            <div className="mobile">
                                                <div className="name">
                                                    <img
                                                        src={chrisName}
                                                        alt="Chris Name"
                                                    />
                                                    <p>
                                                        Living with narcolepsy{" "}
                                                        <br />
                                                        Taking WAKIX since 2020
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-text">
                                            <span className="cta-headline">
                                                “Just knowing that I wasn't
                                                alone in this experience - it
                                                was huge.”
                                            </span>
                                            <div className="desktop">
                                                <div className="name">
                                                    <img
                                                        src={chrisName}
                                                        alt="Chris Name"
                                                    />
                                                    <p>
                                                        Living with narcolepsy{" "}
                                                        <br />
                                                        Taking WAKIX since 2021
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <ThankYouModal open={modalOpen} onClose={closeModal} />
        </HcpLayout>
    );
};

export default ShareYourStoryPage;

export const Head = () => (
    <Seo
        title="Share Your Story | WAKIX® (pitolisant) tablets"
        description=""
        keywords=""
    />
);
